<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :extra-actions="extraActions"
    :filters="filters"
    :filter-vals.sync="filterVals"
    authority="Products_Product"
    single-edit
    inline-actions
  />
</template>

<script>
import { canPublishToShop, canUnPublishFromShop, isSeller } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'
import axios from '@/libs/axios'

import storeModule from '../dealsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      sellerOptions: [],
      supplierOptions: [],
      categoriesOptions: [],
      tagsOptions:[],
      categoryListOptions: [],
    }
  },
  computed: {
    isSeller() {
      return isSeller()
    },
    canPublish() {
      return canPublishToShop()
    },
    canUnPublish() {
      return canUnPublishFromShop()
    },
    config() {
      return {
        store: storeModule,
        module: 'products/deals',
        endpoint: 'products/deals',
        route: 'deals',
        insertRoute: this.isSeller ? '/insert-methods' : false,
        title: {
          single: this.$i18n.t('deal'),
          plural: this.$i18n.t('deals'),
        },
        limited: this.$store.getters['app-common/maxProducts'],
      }
    },
    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'published', label: '' },
        { key: 'featuredImg', label: this.$i18n.t('photo'), sortable: false },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'supplier.title', label: this.$i18n.t('Manufacturer'), sortable: false },
        {
          key: 'seller.title',
          label: this.$i18n.t('Store'),
          sortable: false,
        },
        { key: 'code', label: this.$i18n.t('code'), sortable: true },
        { key: 'regularPrice', label: this.$i18n.t('price'), sortable: true },
        { key: 'quantity', label: this.$i18n.t('qty'), sortable: true },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'published', type: 'status' },
        { key: 'featuredImg', type: 'image' },
      ]
    },

    extraActions() {
      return [
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: this.canPublish,
        },
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: this.canPublish,
        },

        // {
        //   callback: 'updateStockPrice',
        //   endpoint: 'eshop/update-stock-price',
        //   icon: 'UploadCloudIcon',
        //   title: 'Update e-shop Stock and Price',
        //   variant: 'warning',
        //   renderCondition: {
        //     key: 'published',
        //     value: true,
        //   },
        //   authorityValidation: this.isSeller,
        // },
        {
          endpoint: 'eshop/change-product-status',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: !this.isSeller && this.canUnPublish,
        },
      ]
    },

    filters() {
      return [
        {
          name: 'manufacturer',
          label: this.$t('Manufacture'),
          options: this.sellerOptions,
        },
        {
          name: 'store',
          label: this.$t('Store'),
          options: this.supplierOptions,
        },
        {
          name: 'category',
          label: this.$t('category'),
          options: this.categoryListOptions,
        },
        {
          name: 'tags',
          label: this.$t('tags'),
          options: this.tagsOptions,
        },
        {
          name: 'startingfrom',
          type: 'date',
          label: this.$i18n.t('Starting From'),
          maxDateFieldIdx: '4',
          maxDate: new Date(),
        },
        {
          name: 'endsOn',
          type: 'date',
          label: this.$i18n.t('Ends On'),
          minDateFieldIdx: '3',
        },
        // {
        //   name: 'customized',
        //   type: 'boolean',
        //   label: this.$i18n.t('Customized'),
        // },
        // {
        //   name: 'deliveryready',
        //   type: 'boolean',
        //   label: this.$i18n.t('Ready for delivery'),
        // },
        // {
        //   name: 'showroom',
        //   type: 'boolean',
        //   label: this.$i18n.t('Showroom'),
        // },
        // {
        //   name: 'onsale',
        //   type: 'boolean',
        //   label: this.$i18n.t('On Sale'),
        // },
        {
          name: 'status',
          type: 'boolean',
          label: this.$i18n.t('Status'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchSellers').then((response) => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSuppliers').then((response) => {
      this.supplierOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCategories').then((response) => {
      this.categoriesOptions = response.data
    })
    this.$store.dispatch('app-common/fetchTags').then((response) => {
      this.tagsOptions = response.data
    })
    axios.get('categories/list/all-categories').then((response) => {
      this.categoryListOptions = response.data
    })
  },
}
</script>
